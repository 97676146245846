export const SUB_TYPE_OPTIONS = [
  {
    label: "GPS供应商",
    value: 1
  },
  {
    label: "BMS供应商",
    value: 2
  }
];
export const PRODUCT_TYPE = [
  {
    label: "电池",
    value: 1
  },
  {
    label: "电动车",
    value: 2
  }
];
export const BMS_BOARD_TYPE = [
  {
    label: "硬件板",
    value: 1
  },
  {
    label: "软件板",
    value: 2
  }
];

export const STATUS = [
  {
    label: "离线",
    value: 0
  },
  {
    label: "在线",
    value: 1
  }
];
export const WORK_STATUS = [
  {
    label: "充电",
    value: 0
  },
  {
    label: "放电",
    value: 1
  },
  {
    label: "待机",
    value: 2
  }
];

export const ACTIVITY_STATUS = [
  {
    label: "未激活",
    value: 1
  },
  {
    label: "已激活",
    value: 2
  }
];

export const BATTERY_STATUS = [
  {
    label: "正常",
    value: 1
  },
  {
    label: "冻结",
    value: 2
  },
  {
    label: "返修售后",
    value: 3
  }
];

export const POSITION_STATUS = [
  {
    label: "未定位",
    value: 1
  },
  {
    label: "已定位",
    value: 2
  }
];

export const PUBLISH_STATUS = [
  {
    label: "待测试",
    value: 1
  },
  {
    label: "待出库",
    value: 2
  },
  {
    label: "已出库",
    value: 3
  }
];
export const ROLE_OPTIONS = [
  {
    label: "资金方",
    value: 0
  },
  {
    label: "代理商",
    value: 1
  },
  {
    label: "门店",
    value: 2
  }
];
export const SETTLE = [
  {
    label: "次日",
    value: 0
  },
  {
    label: "每周",
    value: 1
  },
  {
    label: "半月",
    value: 2
  },
  {
    label: "每月",
    value: 3
  }
];

export const GOODS_TYPE = [
  {
    label: "电池租赁",
    value: 1
  },
  {
    label: "电动车租赁",
    value: 2
  }
];
export const GOODS_STATUS = [
  {
    label: "在售",
    value: 1
  },
  {
    label: "停售",
    value: 2
  }
];
export const LEASE_STATUS = [
  {
    label: "闲置",
    value: 1
  },
  {
    label: "租赁中",
    value: 2
  }
];

export const HANDLE_STATUS = [
  {
    label: "未处理",
    value: 1
  },
  {
    label: "已处理",
    value: 2
  }
];

export const TROUBLE_REASON = [
  {
    label: "电池充不了电",
    value: 1
  },
  {
    label: "电池离线异常",
    value: 2
  },
  {
    label: "电池 GPS 不定位",
    value: 3
  },
  {
    label: "二维码脱落",
    value: 4
  },
  {
    label: "电池机身破损",
    value: 5
  },
  {
    label: "插头破损",
    value: 6
  },
  {
    label: "电池异常发烫",
    value: 7
  },
  {
    label: "噪音大",
    value: 8
  },
  {
    label: "螺丝脱落",
    value: 9
  },
  {
    label: "充电失效",
    value: 10
  },
  {
    label: "其他",
    value: 11
  }
];

export const AUTH_STATUS = [
  {
    label: "未认证",
    value: "0"
  },
  {
    label: "认证中",
    value: "1"
  },
  {
    label: "认证成功",
    value: "2"
  },
  {
    label: "认证失败",
    value: "3"
  }
];

export const ALIPAY_STATUS = [
  {
    label: "支付宝PID",
    value: 0
  },
  {
    label: "二级商户",
    value: 1
  },
];

export const ENABLE_STATUS = [
  {
    label: "启用",
    value: "1"
  },
  {
    label: "停用",
    value: "2"
  }
];

export const DEAL_STATUS = [
  {
    label: "等待退款",
    value: "1"
  },
  {
    label: "退款成功",
    value: "2"
  },
  {
    label: "退款失败",
    value: "3"
  }
];

export const PAY_TYPE = [
  {
    label: "微信",
    value: 1
  },
  {
    label: "支付宝",
    value: 2
  },
  {
    label: "支付宝代扣",
    value: 3
  },

];

export const EXPIRE_TYPE = [
  {
    label: "10天内",
    value: 1
  },
  {
    label: "7天内",
    value: 2
  },
  {
    label: "3天内",
    value: 3
  },
  {
    label: "已过期",
    value: 4
  }
];

export const ORDER_TYPE = [
  {
    label: "租赁",
    value: 1
  },
  {
    label: "续费",
    value: 2
  },
  {
    label: "更换",
    value: 3
  }
];
// 订单状态
export const ORDER_STATUS = [
  {
    label: "已下单",
    value: 1
  },
  {
    label: "已付款",
    value: 2
  },
  {
    label: "已发货",
    value: 3
  },
  {
    label: "使用中",
    value: 4
  },
  {
    label: "租赁结束",
    value: 5
  },
  {
    label: "支付失败",
    value: 6
  },
  {
    label: "已取消",
    value: 7
  }
];
// 确认状态
export const CONFIRM_TYPE = [
  {
    value: 1,
    label: "未确定"
  },
  {
    value: 2,
    label: "已确定"
  }
];
// 押金状态
export const CASH_PLE_STATUS = [
  {
    value: 1,
    label: "未交押金"
  },
  {
    value: 2,
    label: "已交押金"
  },
  {
    value: 3,
    label: "退款成功"
  }
];

// 分账状态
export const CASH_PLEDGE_STATUS = [
  {
    value: 1,
    label: "待分账"
  },
  {
    value: 2,
    label: "分账中"
  },
  {
    value: 3,
    label: "已分账"
  },
  {
    value: 4,
    label: "分账失败"
  },
  {
    value: 5,
    label: "待结算"
  },
  {
    value: 6,
    label: "结算中"
  },
  {
    value: 7,
    label: "已结算"
  },
  {
    value: 8,
    label: "结算失败"
  },
  {
    value: 9,
    label: "已关闭"
  },
  {
    value: 10,
    label: "已发起"
  }
];

export const RELATION_GOODS_TYPE = [
  {
    value: 4,
    label: "换电卡"
  },
  {
    value: 9,
    label: "电动车"
  },
  {
    value: 10,
    label: "租车卡"
  },
  {
    value: 11,
    label: "充换电套餐"
  }
];
export const SUB_BILL_TYPE = [
  {
    value: 1,
    label: "资金流穿透"
  },
  {
    value: 2,
    label: "系统内记账"
  }
];
/** 电池材料 */
export const BATTERY_MATERIAL = [
  {
    value: 0,
    label: "三元锂"
  },
  {
    value: 1,
    label: "磷酸铁锂"
  }
]
// 电池超温
export const TEMPERATURE_STATUS = [
  {
    value: 0,
    label: "正常"
  },
  {
    value: 1,
    label: "超温"
  }
]
// 充放电保护
export const CHARGE_STATUS = [
  {
    value: 1,
    label: "开启"
  },
  {
    value: 2,
    label: "关闭"
  }
]
// 放电保护
export const DISCHARGE_STATUS = [
  {
    value: 0,
    label: "正常"
  },
  {
    value: 1,
    label: "失效"
  }
]
// 还款状态
export const REPAY_STATUS = [
  {
    value: 0,
    label: "已发起"
  },
  {
    value: 1,
    label: "已完成"
  },
  {
    value: 2,
    label: "还款失败"
  }
]
// 还款结果
export const REPAY_RESULT = [
  {
    value: 0,
    label: "处理中"
  },
  {
    value: 1,
    label: "已还清"
  },
  {
    value: 2,
    label: "需补还"
  },
  {
    value: 3,
    label: "还款溢出"
  }
]
// 结算状态
export const SETTLE_RESULT = [
  {
    value: 0,
    label: "已发起"
  },
  {
    value: 1,
    label: "结算中"
  },
  {
    value: 2,
    label: "已完成"
  },
  {
    value: 3,
    label: "结算失败"
  },
  {
    value: 4,
    label: "结算申请中"
  }
]
// 保险状态
export const INSURE_STATUS = [
  {
    value: 1,
    label: "可用"
  },
  {
    value: 2,
    label: "不可用"
  }
]
// 确认状态
export const CONFIRM_STATUS = [
  {
    value: 1,
    label: "未确认"
  },
  {
    value: 2,
    label: "已确认"
  }
]
// 对账单状态
export const RECONCILIATION_STATUS = [
  {
    value: 1,
    label: "待平台处理"
  },
  {
    value: 2,
    label: "对账单已发起"
  },
  {
    value: 3,
    label: "平台还未确认"
  }
]
// 调整项类型
export const ADJUSTMENT_STATUS = [
  {
    value: 1,
    label: "增加项"
  },
  {
    value: 2,
    label: "扣除项"
  },
]
// 商品类型
export const COMMODITY_TYPE = [
  {
    value: 0,
    label: "租金"
  },
  {
    value: 1,
    label: "保险"
  },
  {
    value: 2,
    label: "充值赠送"
  },

]
// 账本类型
export const ACCOUNT_TYPE = [
  {
    value: 0,
    label: "待结算"
  },
  {
    value: 1,
    label: "已结算"
  },
  {
    value: 2,
    label: "冻结中"
  }
]
// 账方向
export const DIRECTION_TYPE = [
  {
    value: -1,
    label: "出账"//减账
  },
  {
    value: 1,
    label: "入账"//加账
  },
]
// 对账单状态2
  export const DIRECTION_STATUS = [
  {
    value: 1,
    label: "待平台处理"
  },
  {
    value: 2,
    label: "待确认"
  },
  {
    value: 3,
    label: "确认完成"
  },
]
// 正常异常
export const ABNORMAL_STATUS = [
  {
    value: 0,
    label: "正常"
  },
  {
    value: 1,
    label: "异常"
  },
]
// 导出excel
export const IMPORT_STATUS = [
  {
    value: 0,
    label: "待处理"
  },
  {
    value: 1,
    label: "处理中"
  },
  {
    value: 2,
    label: "成功"
  },
  {
    value: 3,
    label: "失败"
  },
]
// 导出任务列表
export const BUSSINESSTYPE_STATUS = [
  {
    value: 50,
    label: "租电运营统计导出"
  },
  {
    value: 51,
    label: "租电订单统计导出"
  },
  {
    value: 52,
    label: "租电对账单统计导出"
  },
  {
    value: 53,
    label: "租电结算订单统计导出"
  },
  {
    value: 54,
    label: "租电还款订单统计导出"
  },
  {
    value: 55,
    label: "租电电池库存统计导出"
  },
  {
    value: 56,
    label: "租电BMS统计导出"
  },
]
