<template>
  <el-form class="form" ref="form" :model="form" :rules="rules" label-width="100px">
    <el-form-item label="供应商名称" prop="name">
      <el-input v-model="form.name" style='width: 300px' placeholder="请输入供应商名称" :maxlength="25" ></el-input>
    </el-form-item>
    <el-form-item label="供应商类型" prop="subType">
      <el-select v-model="form.subType" style='width:300px' placeholder="请选择供应商类型">
        <el-option v-for="opt in SUB_TYPE_OPTIONS" :label="opt.label" :key="opt.value" :value="opt.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="mt20">
      <el-button type="primary" @click="submitForm" :loading="disaled">确认</el-button>
      <el-button type="info" @click="goBack">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { SUB_TYPE_OPTIONS } from '@/js/constant'
import { addSupplier, updateSupplier } from '@/api/product'

export default {
  data() {
    return {
      form: {
        name: '',
        subType: 1,
      },
      rules: {
        name: [
         { required: true, message: '请输入供应商名称', trigger: 'blur' },
        ],
        subType: [
          { required: true, message: '请选择供应商类型', trigger: 'blur' },
        ]
      },
      disaled: false,
      SUB_TYPE_OPTIONS,
    }
  },
  mounted() {
    if (this.$route.query.type == 'edit') {
      this.form = {
        name: this.$route.query.name,
        subType: +this.$route.query.subType,
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.disaled = true
          const params = {
            ...this.form,
            type: 3,
          }
          try {
            if (this.$route.query.type == 'edit') {
                params.number = this.$route.query.number;
                params.orgNo = this.$route.query.orgNo;
                await updateSupplier(params)
            } else {
              await addSupplier(params);
            }
            this.$message.success('操作成功');
            setTimeout(() => {
              this.$router.push('/leaseholdMgmt/supplier')
            }, 1000)
          } catch (error) {
            this.disaled = false
          }
       
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push('/leaseholdMgmt/supplier');

    }
  },
  components: {

  }
}
</script>

<style scoped>

</style>
