import axios from '../js/ajax'
export const getSupplierList = (params) => axios.get('/electricity/battery/supplier/queryNotDelete', {params, loading: true}); // 查询供应商列表
export const addSupplier = params => axios.get('/electricity/battery/supplier/insert', {params}); // 新增供应商
export const updateSupplier = params => axios.get('/electricity/battery/supplier/update', {params}); // 更新供应商

export const getProductList = (params) => axios.get('/managementPlatform/lease/queryProductList', {params, loading: true}); // 查询产品列表
export const addProduct = (params) => axios.postJson('/managementPlatform/lease/addOrUpdateProduct', params); // 新增/编辑 产品
export const delProduct = (params) => axios.get('/managementPlatform/lease/deleteProduct', {params}); // 删除 产品
export const getProductDetail = (params) => axios.get('/managementPlatform/lease/queryProductDetail', {params}) // 获取产品详情

export const updateDistributeRelation = (params) => axios.post('/managementPlatform/fund/lease/updateDistributeRelation', params) // 编辑提交商品

export const findDistributeRelation = (params) => axios.get('/managementPlatform/fund/lease/findDistributeRelation', {params}) // 编辑时获取商品
export const deleteDistributeRelation = (params) => axios.get('/managementPlatform/fund/lease/deleteDistributeRelation', {params}) // 分账删除商品
export const dischargeMosSwitch = (params) => axios.get('/managementPlatform/device/close/dischargeMosSwitch', {params}) // 



